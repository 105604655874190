import ArrowRight from "@/components/icons/ArrowRight";
import BlokWrap from "@/components/ui/BlokWrap";
import { RichTextRender } from "@/components/ui/RichText";
import getLink from "@/lib/storyblok/utils/getLink";
import { HeroCardStoryblok } from "@/lib/types/storyblok-blok-types";
import clsx from "clsx";
import Link from "next/link";
import { Asset, sizes } from "../Asset";
import styles from "./index.module.css";

const isValidLink = (link: HeroCardStoryblok["link"]) => {
  const newLink = link || {};
  return (
    link?.cached_url ||
    ("url" in newLink && newLink?.url) ||
    ("id" in newLink && newLink.id)
  );
};

const CompWrapper = ({
  children,
  link,
}: {
  children: any;
  link: HeroCardStoryblok["link"];
}) => {
  if (isValidLink(link)) {
    const href = getLink(link);
    return (
      <Link href={href} className={styles.contentContainer}>
        {children}
      </Link>
    );
  }
  return <div className={styles.contentContainer}>{children}</div>;
};

export const HeroCard = ({
  blok,
  restProps,
}: {
  blok: HeroCardStoryblok;
  restProps: {
    pageOrder: number | undefined;
    animation: "fadeY" | "fadeX" | "fadeXinvert";
  };
}) => {
  const { media, title, description, link, sticky } = blok;

  const getColorProps = () => {
    if ("titleColor" in blok) {
      const blokToUse = blok as typeof blok & {
        titleColor: {
          color: string;
        };
      };

      if (!blokToUse.titleColor.color) return {};

      return {
        style: {
          color: blokToUse.titleColor.color,
        },
      };
    }
    return {};
  };

  const validLink = isValidLink(link);

  return (
    <BlokWrap
      data-layout="page"
      editable={blok}
      animated={restProps.animation}
      className={clsx(styles.hero, "hero")}
    >
      <CompWrapper link={link}>
        {media && (
          <Asset
            src={media?.[0]}
            sizes={sizes(100, 40)}
            quality={90}
            priority={restProps.pageOrder === 1 ? true : undefined}
            autoPlay={true}
            loop={true}
            muted={true}
          />
        )}

        {title && (
          <div className={clsx(styles.content)}>
            <div
              className={clsx(styles.headerAndTitle, sticky && styles.sticky)}
            >
              {blok.heading && (
                <h1 className={styles.heading}>{blok.heading}</h1>
              )}
              <div
                style={{ ...getColorProps().style }}
                className={clsx(styles.title, validLink && styles.link)}
              >
                <h2>{title}</h2>
                {validLink && <ArrowRight />}
              </div>
            </div>
          </div>
        )}
      </CompWrapper>
      {description && <RichTextRender blok={description} />}
    </BlokWrap>
  );
};
