import { forwardRef, Suspense } from "react";

import type { BlokParameters } from "../blok-component-types";

import ClientComponent from "./ClientComponent";
import ServerComponent from "./ServerComponent";

export type StoryblokComponentProps<T = BlokParameters[keyof BlokParameters]> =
  T extends BlokParameters[keyof BlokParameters]
    ? {
        blok: T;
        preview?: boolean;
        [key: string]: unknown;
      }
    : never;

const StoryblokComponent = forwardRef<HTMLElement, StoryblokComponentProps>(
  ({ blok, preview = false, page, locale, ...restProps }, ref) => {
    if (!blok) {
      const errorMessage = "Please provide a blok";
      console.error(errorMessage);
      return <div>{errorMessage}</div>;
    }

    if (!blok.component) {
      const errorMessage = `Component is not listed in bloks`;
      console.error(errorMessage);
      return <div>{errorMessage}</div>;
    }

    if (preview) {
      return (
        <Suspense>
          <ClientComponent
            key={blok.component}
            blok={blok}
            preview
            restProps={restProps}
            ref={ref}
            locale={locale}
            page={page}
          />
        </Suspense>
      );
    }
    return (
      <ServerComponent
        key={blok.component}
        blok={blok}
        restProps={restProps}
        ref={ref}
        locale={locale}
        page={page}
      />
    );
  },
);

StoryblokComponent.displayName = "StoryblokComponent";

export default StoryblokComponent;
