import Link from "next/link";

import type { MediaElementStoryblok } from "@/lib/types/storyblok-blok-types";

import { Asset, sizes } from "@/components/blocks/atoms/Asset";
import clsx from "clsx";
import styles from "./index.module.css";

export type ArticleCardType = {
  name: string;
  image?: MediaElementStoryblok;
  slug: string;
  lead?: string;
  variant?: number;
  isFeaturedArticle?: boolean;
  inherit?: boolean;
  className?: string;
  pageOrder?: number | undefined;
};
const ArticleCard = ({
  name,
  image,
  slug,
  lead,
  variant,
  isFeaturedArticle,
  inherit,
  className,
  pageOrder,
}: ArticleCardType) => {
  return (
    <div
      className={clsx(
        inherit && styles.inherit,
        isFeaturedArticle ? styles.featuredArticle : "",
        "article-card",
        styles.wrapper,
        variant === 1
          ? styles.variant1
          : variant === 2
            ? styles.variant2
            : styles.variant3,
        className,
      )}
    >
      <Link href={`/${slug}`} className={styles.inner}>
        <div className={styles.imageContainer}>
          {image && (
            <Asset
              src={image}
              sizes={sizes(100)}
              autoPlay={true}
              controls={true}
              loop={true}
              muted={true}
              priority={pageOrder === 1 ? true : undefined}
            />
          )}
        </div>
        <div className={styles.title}>
          <h3 className={styles.name}>{name}</h3>
          <div className={styles.excerpt}>{lead}</div>
        </div>
      </Link>
    </div>
  );
};

export default ArticleCard;

export const EmptyArticleCard = () => {
  return <div className={styles.wrapper} aria-hidden></div>;
};
