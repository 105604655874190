import { storyblokEditable, type SbBlokData } from "@storyblok/react/rsc";

import type { MediaElementStoryblok } from "@/lib/types/storyblok-blok-types";

import { Asset, sizes } from "../Asset";
import styles from "./sb-media.module.css";

type Props = {
  blok: MediaElementStoryblok;
  restProps: {
    pageOrder: number | undefined;
  };
};

const MediaElement = ({ blok, restProps }: Props) => {
  if (!blok) return <></>;
  return (
    <div
      data-blok
      {...storyblokEditable(blok as unknown as SbBlokData)}
      className={styles.wrapper}
    >
      <Asset
        src={blok}
        sizes={sizes(100)}
        quality={90}
        muted={true}
        autoPlay
        loop={true}
        priority={restProps.pageOrder === 1 ? true : undefined}
      />
    </div>
  );
};

export default MediaElement;
