"use client";

import type { SbBlokData } from "@storyblok/react/rsc";
import { storyblokEditable } from "@storyblok/react/rsc";

import Link from "@/components/blocks/atoms/Link";
import { useDebounce } from "@/hooks/useDebounce";
import {
  LinkStoryblok,
  MegaHeaderStoryblok,
} from "@/lib/types/storyblok-blok-types";
import clsx from "clsx";
import { Fragment, useEffect, useState } from "react";

import styles from "./index.module.css";
import {
  MenuSectionItem,
  MenuSectionLink,
  MenuSectionModal,
} from "./MenuSectionModal";

const HeaderBloks = ({ blok }: { blok: MegaHeaderStoryblok }) => {
  const { links, secondary } = blok;
  const [_activeSection, setActiveSection] = useState<string>("");
  const [open, setOpen] = useState(false);
  const activeSection = useDebounce(_activeSection, open ? 200 : 0);
  useEffect(() => {
    if (activeSection !== "") {
      setOpen(true);
      document.body.classList.add("overlay");
      //document.body.classList.add("scroll-locked");
    } else {
      setOpen(false);
      document.body.classList.remove("overlay");
      //document.body.classList.remove("scroll-locked");
    }
  }, [activeSection]);

  const menuWrapperStyles = clsx(
    styles.menuWrapper,
    activeSection !== "" && styles.hasActive,
  );

  return (
    <nav
      {...storyblokEditable(blok as unknown as SbBlokData)}
      className={clsx(menuWrapperStyles)}
    >
      <ul className={styles.outerMenu}>
        {links?.map((link: any) => (
          <Fragment key={link._uid}>
            <MenuSectionLink
              link={link}
              setActiveSection={setActiveSection}
              open={activeSection === link._uid}
            />
          </Fragment>
        ))}
      </ul>
      <div
        className={clsx(
          styles.menuModalWrapper,
          activeSection !== "" && styles.open,
        )}
      >
        {links?.map((link: any) => (
          <MenuSectionModal
            key={"modal" + link._uid}
            open={activeSection === link._uid}
            setActiveSection={setActiveSection}
          >
            <MenuSectionItem
              link={link}
              active={activeSection === link._uid}
              setActiveSection={setActiveSection}
            />
          </MenuSectionModal>
        ))}
      </div>

      {secondary && (
        <div className={styles.secondaryLinks}>
          {secondary.map((link: LinkStoryblok) => (
            <Link blok={link} key={link._uid} />
          ))}
        </div>
      )}
    </nav>
  );
};

export default HeaderBloks;
