import ArrowRight from "@/components/icons/ArrowRight";
import BlokWrap from "@/components/ui/BlokWrap";
import getLink from "@/lib/storyblok/utils/getLink";
import { HeroStoryblok, LinkStoryblok } from "@/lib/types/storyblok-blok-types";
import clsx from "clsx";
import Link from "next/link";
import { ReactNode } from "react";
import { Asset, sizes } from "../../atoms/Asset";
import styles from "./index.module.css";

export const NewHero = ({
  blok,
  restProps,
}: {
  blok: HeroStoryblok;
  restProps?: {
    column: boolean;
    pageOrder: number | undefined;
  };
}) => {
  const { media, title, links } = blok;

  return (
    <BlokWrap
      data-layout="page"
      editable={blok}
      className={clsx(styles.hero, "hero")}
    >
      <div className={styles.contentContainer}>
        {media && (
          <Asset
            src={media?.[0]}
            sizes={sizes(100)}
            quality={90}
            priority={restProps?.pageOrder === 1 ? true : undefined}
          />
        )}

        {title && (
          <div className={styles.content}>
            <div className={styles.sticky}>
              <h1 className={clsx(styles.title)}>{title} </h1>
              {links && (
                <div className={styles.linkWrapper}>
                  {links.map((link, index) => (
                    <div key={index} className={styles.links}>
                      <HeroLink link={link}>
                        <ArrowRight />
                      </HeroLink>
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    </BlokWrap>
  );
};

const HeroLink = ({
  link,
  children,
}: {
  link: LinkStoryblok;
  children: ReactNode;
}) => {
  const href = getLink(link.link);
  return (
    <Link className={styles.link} href={href}>
      {link.text}
      {children}
    </Link>
  );
};
