import getLink from "@/lib/storyblok/utils/getLink";
import { ArticlesStoryblok } from "@/lib/types/storyblok-blok-types";
import { ArticlesUI } from "./ArticlesUI";
import { ClientArticles } from "./ClientArticles";
import { getManualArticles } from "./actions";

export const ServerArticles = async ({
  blok,
  preview,
  restProps,
}: {
  blok: ArticlesStoryblok;
  preview?: boolean;
  restProps: {
    pageOrder: number | undefined;
  };
}) => {
  const articles = await getManualArticles(blok?.articles, preview);

  const link = getLink(blok?.link?.[0]?.link);
  return (
    <ArticlesUI
      pageOrder={restProps.pageOrder}
      articles={articles}
      blok={blok}
      preview={preview}
      link={link}
    />
  );
};

export const Articles = ({
  blok,
  preview,
  restProps,
}: {
  blok: ArticlesStoryblok;
  preview?: boolean;
  restProps: {
    pageOrder: number | undefined;
  };
}) => {
  if (preview) {
    return (
      <ClientArticles pageOrder={restProps.pageOrder} blok={blok} preview />
    );
  }
  return <ServerArticles restProps={restProps} blok={blok} preview={preview} />;
};
