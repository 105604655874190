import BlokWrap from "@/components/ui/BlokWrap";
import StoryblokComponent from "@/lib/storyblok/StoryblokComponent/StoryblokComponent";
import { SideBySideStoryblok } from "@/lib/types/storyblok-blok-types";
import clsx from "clsx";
import styles from "./index.module.css";

interface Props {
  blok: SideBySideStoryblok;
  preview?: boolean;
  restProps?: {
    pageOrder: number | undefined;
  };
}

export const SideBySide = ({ blok, preview, restProps }: Props) => {
  const left = blok.left?.[0];
  const right = blok.right?.[0];

  return (
    <BlokWrap
      animated="custom"
      data-layout={"page"}
      editable={blok}
      className={styles.root}
    >
      {left ? (
        <Side
          blok={left}
          size={blok.leftWidth}
          align={blok.leftAlignment}
          preview={preview}
          animate="fadeY"
          pageOrder={restProps?.pageOrder}
        />
      ) : (
        <div aria-hidden />
      )}
      {right ? (
        <Side
          blok={right}
          animate="fadeY"
          size={blok.rightWidth}
          preview={preview}
          pageOrder={restProps?.pageOrder}
        />
      ) : (
        <div aria-hidden />
      )}
    </BlokWrap>
  );
};

interface SideProps {
  blok: any;
  preview?: boolean;
  align?: string;
  size?: string;
  animate?: string;
  pageOrder: number | undefined;
}

const alignment: Record<string, string> = {
  left: styles.alignLeft,
  right: styles.alignRight,
  center: styles.alignCenter,
};

const sideSize: Record<string, string> = {
  wide: styles.wide,
  large: styles.wide,
  small: styles.normal,
};

const Side = ({
  blok,
  size,
  align,
  preview,
  animate,
  pageOrder,
}: SideProps) => {
  const content = blok;
  const sizeClass = sideSize[size ?? ""];
  const alignClass = alignment[align ?? ""] || "";
  let alignSelf = null;
  if (blok?.component === "content" || blok?.component === "quote") {
    alignSelf = styles.alignSelfCenter;
  }

  return (
    <article className={clsx(styles.side, sizeClass, alignClass, alignSelf)}>
      <StoryblokComponent
        blok={content}
        preview={preview}
        column={true}
        animation={animate}
        pageOrder={pageOrder}
      />
    </article>
  );
};
