"use client";
import BlokWrap from "@/components/ui/BlokWrap";
import { HubspotFormStoryblok } from "@/lib/types/storyblok-blok-types";
import Script from "next/script";
import { useCallback, useEffect, useId, useState } from "react";
import { ErrorBoundary } from "react-error-boundary";
import styles from "./index.module.css";

type Props = {
  blok: HubspotFormStoryblok;
};

export const HubspotFormComponent = ({ formId }: { formId: string }) => {
  const [loaded, setLoaded] = useState(false);
  const id = useId();
  const formattedId = id.replaceAll(":", "_");

  const createForm = useCallback(() => {
    if (!formId) return;

    if (window.hbspt) {
      window.hbspt.forms.create({
        portalId: "144329444",
        formId: String(formId),
        target: `#${formattedId}`,
        css: formCSS,
      });
    }
  }, [formId, formattedId]);

  useEffect(() => {
    if (!loaded) return;
    createForm();
  }, [createForm, loaded]);

  return (
    <>
      <div id={formattedId} className={styles.hubspotForm} />
      <Script
        onLoad={() => {
          setLoaded(true);
        }}
        strategy="afterInteractive"
        src="https://js.hsforms.net/forms/v2.js"
      />
    </>
  );
};

const HubspotForm = ({ blok }: Props) => {
  return (
    <BlokWrap editable={blok} animated="fadeY">
      {blok.id && typeof blok.id === "string" && (
        <HubspotFormComponent formId={blok.id} />
      )}
    </BlokWrap>
  );
};

const HubspotFormWithFallback: typeof HubspotForm = (props) => {
  return (
    <ErrorBoundary
      fallback={
        <div data-layout="page">
          <p>⚠️ This form could not be loaded</p>
        </div>
      }
    >
      <HubspotForm {...props} />
    </ErrorBoundary>
  );
};

export default HubspotFormWithFallback;

declare global {
  interface Window {
    hbspt?: {
      forms: {
        create: (config: {
          portalId: string;
          formId: string;
          target: string;
          css: string;
        }) => void;
      };
    };
  }
}

const formCSS = `

/* General Styles for HubSpot Form as they must now be injected */
.hbspt-form {
  font-family: inherit;
  line-height: 1.5;
  & > * {
    font-family: inherit;
  }

  & ul {
    padding-inline-start: 0;
  }
}

body {
  /* background-color: red !important; */
}


.input {
  
  input[type="email"], input[type="text"] {
      height: 50px !important;
      background-color: white;
      border-top: none;
      border-left: none;
      border-right: none;
        
  }

    input[type="checkbox"] {
      accent-color: var(--color-dark) !important;
  }
} 

/* Input Fields */
.hs-input {
  width: 100%;
  padding: 12px 16px !important;
  font-size: 14px;
  border: 1px solid var(--color-input-border); /* Default border color */
  border-radius: 0px;
  color: #333;
  background-color: transparent;
  box-sizing: border-box;
  transition:
    border-color 0.3s ease,
    box-shadow 0.3s ease;
}

/* Input States */
.hs-input:focus {
  border-color: var(--color-input-focus); /* Keyboard focus color */
  box-shadow: 0 0 4px rgba(0, 115, 230, 0.5);
  outline: 1px solid var(--color-input-focus);
}

.hs-input:hover {
  border-color: #999; /* Hover border color */
}

.form-columns-2 {
  display: flex;
  gap: 8px;

  @media(--medium) {
    gap: 16px;
  }


}

.hs-error-msgs {
  margin-top: 4px;
}

.hs-input:disabled {
  background-color: #f2f2f2;
  border-color: #ccc;
  cursor: not-allowed;
  color: var(--color-input-focus);
}

.hs-input.error {
  border-color: #e63946; /* Error border color */
  background-color: #fddcdc;
}

/* Placeholder Text */
.hs-input::placeholder {
  color: #aaa;
  font-style: italic;
}

.hs-form-field {
   display: flex;
   flex-direction: column;
   gap: 2px;
   margin-top: 4px;
}

.hs-form-field label {
  @extend %typo-label;
  line-height: 1.5;
  font-weight: 400;
  flex-direction: column;

  width: 100%;
  font-size: 11px;

  &.error {
    & .labelText {
      color: var(--color-red);
    }

    & .input {
      --border-color: var(--color-red);
    }
  }
}

/* Labels */
.hs-form-field label.hs-label {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-3xs);
  width: 100%;
  --input-size: min(var(--body2-size), 16px);
  & .labelText {
    @extend %typo-label;
    line-height: 1.5;
    font-weight: 400;
  }

  &.error {
    & .labelText {
      color: var(--color-red);
    }

    & .input {
      --border-color: var(--color-red);
    }
  }
}

/* Error Messages */
.hs-error-msg {
  font-size: 12px;
  color: #e63946;
  margin-top: 4px;
}

/* Text Area */
textarea.hs-input {
  min-height: 120px;
  resize: vertical;
}

fieldset.form-columns-2 .input {
  margin-right: 0;
}

/* Select Dropdown */
select.hs-input {
  appearance: none;
  background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg xmlns="http://www.w3.org/2000/svg" width="10" height="6" viewBox="0 0 10 6"%3E%3Cpath fill="none" stroke="%23999" stroke-width="2" d="M1 1l4 4 4-4"%3E%3C/path%3E%3C/svg%3E');
  background-repeat: no-repeat;
  background-position: right 12px center;
  background-size: 10px 6px;
  padding-right: 36px;
}

/* Checkboxes and Radios */
.hs-form input[type="checkbox"],
.hs-form input[type="radio"] {
  accent-color: #0073e6; /* Match the active state color */
  margin-right: 8px;
}

/* Tabs and Switches (if applicable) */
.hs-form .hs-switch {
  display: inline-block;
  position: relative;
  width: 34px;
  height: 20px;
  background-color: #ccc;
  border-radius: 20px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.hs-form .hs-switch::before {
  content: "";
  position: absolute;
  top: 2px;
  left: 2px;
  width: 16px;
  height: 16px;
  background-color: #fff;
  border-radius: 50%;
  transition: transform 0.3s;
}

.hs-form .hs-switch.active {
  background-color: #0073e6;
}

.hs-form .hs-switch.active::before {
  transform: translateX(14px);
}

.hs-form {
  & .inputs-list {
    & li:only-child {
      list-style: none;
    }

    & li:has(input) {
      list-style: none;
    }
  }
}

.hs-form input[type="submit"]:hover {
  background-color: var(--neutral-7);
}

.hs-form input[type="submit"]:disabled {
  background-color: var(--neutral-4);
  color: var(--color-dark);
  cursor: not-allowed;
}

.hs-form {
  & input[type="submit"] {
    pointer-events: auto !important;
    --arrow-size: 24px;
    -webkit-font-smoothing: antialiased;
    box-sizing: border-box;
    --button-padding-h: 24px;
    --button-padding-v: 12px;
    --button-br: 0;
    --button-gap: 8px;
    --svg-color: var(--color-white);
    min-width: 80px;
    transition: all ease 150ms;
    cursor: pointer;
    -webkit-box-align: center;
    align-items: center;
    background: var(--color-dark);
    color: var(--color-white);
    padding: var(--button-padding-v) var(--button-padding-h);
    border-radius: var(--button-br);
    appearance: none;
    border: 0;
    text-decoration: none;
    text-align: center;
    font-family: inherit;

    font-size: var(--font-size-s);
    font-weight: 500;
    line-height: 1;
    text-transform: none;
    height: var(--pdp-button-height);
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    align-content: center;
    width: fit-content;
    margin-top: 8px;
  }
}

.legal-consent-container .hs-form-booleancheckbox-display>span {
  margin-left: 8px !important;
}

.hs-form fieldset {
  margin-top: var(--spacing-xs) !important;
  max-width: unset !important;

  &.form-columns-1 .hs-input {
    &select,
    &input:not([type="radio"]):not([type="checkbox"]) {
      width: 100% !important;
    }
  }

  & textarea {
    width: 100% !important;
  }
}

.hs-form-booleancheckbox-display {
  display: flex;
  flex-direction: row !important;
  align-items: center;
  input {
    width: fit-content !important;
  }

  a {
    color: currentColor;
  }
}

.inputs-list {
  padding-left: 0 !important;
}

`;
