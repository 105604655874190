"use client";

import { getAllStories } from "@/lib/storyblok/storyblokSetup";
import { ArticleStoryblok } from "@/lib/types/storyblok-blok-types";
import { useQuery } from "@tanstack/react-query";
import type { ArticleCategoryProps } from "./ArticleCategory";
import { ArticleCategoryUI } from "./ArticleCategoryUI";
import { getFeaturedArticle } from "./actions";

export const ClientArticleCategory = ({
  blok,
  path,
  locale,
  pageOrder,
}: ArticleCategoryProps) => {
  const { data } = useQuery({
    queryKey: ["articleCategory", blok, path],
    queryFn: async () => {
      const featuredArticle = await getFeaturedArticle(
        blok.featuredArticle,
        true,
      );

      let articles = await getAllStories<ArticleStoryblok>(
        path,
        undefined,
        true,
      );
      if (articles) {
        articles = articles.filter(
          (article) =>
            !article.is_startpage && article.uuid !== featuredArticle?.uuid,
        );
      }

      return {
        articles,
        featuredArticle,
      };
    },
  });

  const { articles, featuredArticle } = data ?? {};
  return (
    <ArticleCategoryUI
      pageOrder={pageOrder}
      blok={blok}
      articles={articles ?? []}
      featuredArticle={featuredArticle}
      tabs={blok.tabs || []}
      locale={locale || ""}
    />
  );
};
