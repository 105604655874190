import { RichText } from "@/components/ui/RichText";
import StoryblokComponent from "@/lib/storyblok/StoryblokComponent/StoryblokComponent";
import { TextHeroStoryblok } from "@/lib/types/storyblok-blok-types";
import styles from "./index.module.css";
type Props = {
  blok: TextHeroStoryblok;
  restProps: {
    pageOrder: number | undefined;
  };
};
export const TextHero = ({ blok, restProps }: Props) => {
  const { title, text, link } = blok;
  return (
    <article className={styles.textHero}>
      <div className={styles.text}>
        <h2 className={styles.title}>{title}</h2>
        <RichText>{text}</RichText>
      </div>
      {link?.[0] && (
        <StoryblokComponent restProps={restProps.pageOrder} blok={link?.[0]} />
      )}
    </article>
  );
};
