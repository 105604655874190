"use client";
import BlokWrap from "@/components/ui/BlokWrap";
import { ArticleHeroStoryblok } from "@/lib/types/storyblok-blok-types";
import clsx from "clsx";
import { useLayoutEffect, useState } from "react";
import { useInView } from "react-intersection-observer";
import MediaElement from "../../atoms/MediaElement";
import {
  HeroFullTextBottomContent,
  HeroFullTextLeftContent,
  HeroFullTextRightContent,
  LeftHeroContent,
} from "./ArticleHeroContent";
import styles from "./index.module.css";

export const ArticleHero = ({
  blok,
  preview,
}: {
  blok: ArticleHeroStoryblok;
  preview?: boolean;
}) => {
  const { title, text, byline, media, mediaAlignment, textAlignment } = blok;
  const [scrollPosition, setScrollPosition] = useState(100);
  const heroAlignment = clsx({
    [styles.heroLeft]: mediaAlignment === "left",
    [styles.heroFullOverlayText]:
      mediaAlignment === "full" &&
      (textAlignment === "right" || textAlignment === "left"),
    [styles.heroFullBottomText]:
      mediaAlignment === "full" && textAlignment === "bottom",
  });

  const { ref, inView, entry } = useInView({
    threshold: 0,
  });

  useLayoutEffect(() => {
    const isFullOverlayText = (
      entry?.target.firstChild as HTMLElement
    )?.className.includes(styles.heroFullOverlayText);

    if (!inView || !isFullOverlayText) return;

    const handleScroll = () => {
      const clientRect = entry?.target.getBoundingClientRect();
      if (!clientRect) {
        return;
      }
      const { height, top } = clientRect;
      const visibleArea = ((height + top) / height) * 100;

      setScrollPosition(
        visibleArea < 100 ? (visibleArea > 0 ? visibleArea : 0) : 100,
      );
    };
    handleScroll();
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [inView, entry, ref]);

  return (
    <BlokWrap>
      <div className={styles.wrapper} ref={ref}>
        {media?.[0] && (
          <div
            data-layout="page"
            className={clsx(heroAlignment, styles.heroMedia)}
          >
            <MediaElement
              blok={media[0]}
              restProps={
                {
                  pageOrder: 1,
                  position: `-${(100 - scrollPosition) / 3}%`,
                } as any
              }
            />
          </div>
        )}

        <div
          data-layout="page"
          className={clsx(
            styles.wrapper,
            mediaAlignment === "full" &&
              ["right", "left"].includes(textAlignment) &&
              styles.mediaFullContentInside,
            mediaAlignment === "left" && styles.contents,
          )}
        >
          {mediaAlignment === "left" && (
            <>
              <LeftHeroContent
                title={title || ""}
                text={text || ""}
                byline={byline || ""}
              />
            </>
          )}

          {textAlignment === "bottom" && mediaAlignment === "full" && (
            <>
              <HeroFullTextBottomContent
                title={title || ""}
                text={text || ""}
                byline={byline || ""}
              />
            </>
          )}

          {textAlignment === "right" && mediaAlignment === "full" && (
            <>
              <HeroFullTextRightContent
                title={title || ""}
                text={text || ""}
                byline={byline || ""}
              />
            </>
          )}

          {textAlignment === "left" && mediaAlignment === "full" && (
            <>
              <HeroFullTextLeftContent
                title={title || ""}
                text={text || ""}
                byline={byline || ""}
              />
            </>
          )}
        </div>
      </div>
    </BlokWrap>
  );
};
