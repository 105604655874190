import env from "@/env.mjs";

const videoExtensions = ["mp4", "webm", "ogg"];

const getStreamVideoUrl = (url: string | undefined) => {
  if (!url) {
    return undefined;
  }
  try {
    const pathname = new URL(url).pathname;

    if (!videoExtensions.some((ext) => pathname.endsWith(`.${ext}`))) {
      return url;
    }

    return `${
      env.NEXT_PUBLIC_STREAM_URL
    }/video/v1?url=${encodeURIComponent(url)}`;
  } catch (error) {
    return url;
  }
};

export { getStreamVideoUrl };
