import type { SbBlokData } from "@storyblok/react/rsc";
import { storyblokEditable } from "@storyblok/react/rsc";

import StoryblokComponent from "@/lib/storyblok/StoryblokComponent/StoryblokComponent";
import type { ArticleStoryblok } from "@/lib/types/storyblok-blok-types";
import clsx from "clsx";
import { Suspense } from "react";

const Article = ({
  blok,
  page,
  locale,
  preview,
}: {
  blok: ArticleStoryblok;
  page?: string[];
  locale?: string;
  preview?: boolean;
}) => {
  const articleTop = blok.articleTop?.[0];
  return (
    <div
      {...storyblokEditable(blok as unknown as SbBlokData)}
      className={clsx(blok?.template === "simple" && "layout-simple")}
    >
      {articleTop && (
        <StoryblokComponent pageOrder={1} preview={preview} blok={articleTop} />
      )}
      {blok.body?.map((blok, i) => (
        <Suspense fallback={null} key={blok._uid}>
          <StoryblokComponent
            preview={preview}
            blok={blok}
            key={blok._uid}
            pageOrder={articleTop ? i + 2 : i + 1}
          />
        </Suspense>
      ))}
    </div>
  );
};

export default Article;
