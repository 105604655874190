"use client";
import { HubspotFormComponent } from "@/components/blocks/sections/HubspotForm/HubspotForm";
import styles from "./index.module.css";

interface INewsletterSection {
  newsletterTitle?: string;
  newsletterText?: string;
  formId: string;
}

const NewsletterSection = ({
  newsletterTitle,
  newsletterText,
  formId,
}: INewsletterSection) => {
  return (
    <div className={styles.newsletter}>
      <div>
        <div className={styles.newsletter__inner}>
          {newsletterTitle && (
            <h2 className={styles.newsletter__title}>{newsletterTitle}</h2>
          )}
          {newsletterText && (
            <div>
              <p>{newsletterText}</p>
            </div>
          )}

          {formId && (
            <div className={styles.formWrapper}>
              <HubspotFormComponent formId={formId} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default NewsletterSection;
