"use client";

import { getImageProps, type ImageProps } from "next/image";
import { type ComponentProps, memo, useMemo } from "react";

interface ImageSrc {
  width?: number;
  height?: number;
  quality?: number;
  src: string;
  fill?: boolean;
  media?: string;
  loader?: ImageProps["loader"];
}

interface Base
  extends Omit<ComponentProps<"img">, "src" | "alt" | "width" | "height"> {
  alt: string;
  sizes: string;
  fill?: boolean;
  width?: number;
  height?: number;
  quality?: number;
  focus?: string;
  loader?: ImageProps["loader"];
  loading?: ImageProps["loading"];
  fetchPriority?: ImageProps["fetchPriority"];
}

export interface PictureArtDirectionProps extends Base {
  media: {
    [key: string]: ImageSrc | string;
  };
  focus?: string;
  src?: string;
}

export interface PictureNormalProps extends ImageSrc, Base {
  media?: never;
}

export type PictureProps = PictureArtDirectionProps | PictureNormalProps;

export const Picture = memo(
  ({ src, media, focus, alt, ...common }: PictureProps) => {
    const firstSrcSet = useMemo(
      () => src || Object.values(!media)[0],
      [media, src],
    );

    const {
      props: { ...rest },
    } = getImageProps({
      ...common,
      ...(typeof firstSrcSet === "string" ? { src: firstSrcSet } : firstSrcSet),
    });

    return (
      <picture style={rest.style}>
        {media &&
          Object.entries(media).map(([media, src]) => {
            const {
              props: { srcSet },
            } = getImageProps({
              ...common,
              ...(typeof src === "string" ? { src } : src),
              alt: "",
            });
            return <source key={media} srcSet={srcSet} media={media} />;
          })}
        {/* biome-ignore lint/a11y/useAltText: <explanation> */}
        <img
          alt={alt}
          {...rest}
          style={{
            objectPosition: focus,
            width: "100%",
            height: "100%",
            objectFit: "cover",
          }}
        />
      </picture>
    );
  },
);

Picture.displayName = "Picture";
