import { ArticleCategoryStoryblok } from "@/lib/types/storyblok-blok-types";
import ServerArticleCategory from "./ArticleCategory";
import { ClientArticleCategory } from "./ClientArticleCategory";

export type ArticleCategoryProps = {
  blok: ArticleCategoryStoryblok;
  locale?: string;
  page?: string[];
  preview?: boolean;
  restProps: {
    pageOrder: number | undefined;
  };
};

export const ArticleCategory = ({
  blok,
  locale,
  page,
  preview,
  restProps,
}: ArticleCategoryProps) => {
  let path = locale ? locale : "";
  if (page) {
    path += "/" + page.join("/");
  }

  if (preview) {
    return (
      <ClientArticleCategory
        pageOrder={restProps.pageOrder}
        blok={blok}
        locale={locale}
        path={path}
        preview
      />
    );
  }

  return (
    <ServerArticleCategory
      pageOrder={restProps.pageOrder}
      blok={blok}
      locale={locale}
      path={path}
    />
  );
};
