import { Picture } from "@/components/ui/Picture";
import FormatStoryblokMedia, {
  FormattedMedia,
} from "@/lib/storyblok/utils/Media/FormatStoryblokMedia";
import { MediaElementStoryblok } from "@/lib/types/storyblok-blok-types";
import { getStreamVideoUrl } from "@/lib/utils/video";
import styles from "./Asset.module.css";
/**
 * Produces media sizes for mobile first
 * @example
 * sizes(100, 50, 25)
 * // 100vw, (min-width: 768px) 50vw, (min-width: 1024px) 25vw
 *
 * @description this is used to produce the sizes attribute for the Image component
 */
export const sizes = (main: number, md?: number, lg?: number) => {
  const sizes: string[] = [];

  if (lg) {
    sizes.push(`(min-width: 1024px) ${lg}vw`);
  }

  if (md) {
    sizes.push(`(min-width: 768px) ${md}vw`);
  }

  sizes.push(`${main}vw`);

  return sizes.join(", ");
};

export interface AssetProps {
  src: MediaElementStoryblok;
  sizes?: string;
  alt?: string;

  autoPlay?: boolean;
  controls?: boolean;
  loop?: boolean;
  muted?: boolean;
  priority?: boolean;
  quality?: number;
}

/**
 * Asset component - uses FILL by default.
 * To use this component you must provide a container with the valid dimensions / aspect-ratio
 */
export const Asset = ({
  src,
  sizes,
  autoPlay,
  loop,
  muted,
  priority,
  quality,
}: AssetProps) => {
  const { mobile, desktop } = FormatStoryblokMedia(src);
  if (!mobile || !desktop) return null;

  if (mobile.isImage && desktop.isImage) {
    return (
      <Picture
        src={mobile.file ? mobile.file : desktop.file}
        media={{
          "(min-width: 768px)": desktop.file ? desktop.file : mobile.file,
        }}
        focus={desktop?.focus}
        alt={desktop?.alt}
        quality={quality}
        sizes={sizes || "100vw"}
        loading={priority ? "eager" : "lazy"}
        decoding="async"
        fill
        fetchPriority={priority ? "high" : "auto"}
      />
    );
  }

  const assetProps = { autoPlay, loop, muted, priority };

  return (
    <>
      <AssetRenderer {...assetProps} src={desktop} className={styles.desktop} />
      <AssetRenderer
        {...assetProps}
        src={mobile?.file ? mobile : desktop}
        className={styles.mobile}
      />
    </>
  );
};

const AssetRenderer = ({
  src,
  sizes,
  quality,
  priority,
  className,
  autoPlay,
  loop,
  muted,
}: {
  src: FormattedMedia["desktop"];
  sizes?: string;
  quality?: number;
  priority?: boolean;
  className?: string;
  autoPlay?: boolean;
  loop?: boolean;
  muted?: boolean;
  controls?: boolean;
}) => {
  if (src?.isImage) {
    return (
      <Picture
        className={className}
        focus={src?.focus}
        alt={src?.alt}
        quality={quality}
        sizes={sizes || "100vw"}
        loading={priority ? "eager" : "lazy"}
        decoding="auto"
        fill
        fetchPriority={priority ? "high" : "auto"}
        src={src.file}
      />
    );
  }

  if (src?.isVideo) {
    return (
      <video
        width={"100%"}
        height={"100%"}
        playsInline={true}
        src={getStreamVideoUrl(src.file)}
        controls={false}
        {...{ autoPlay, loop, muted, sizes }}
        className={className}
      >
        <source src={getStreamVideoUrl(src.file)} />
      </video>
    );
  }

  return null;
};
