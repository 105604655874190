"use client";
import type { SbBlokData } from "@storyblok/react/rsc";
import { storyblokEditable } from "@storyblok/react/rsc";
import clsx from "clsx";

import BlokWrap from "@/components/ui/BlokWrap";
import type { VideoSectionStoryblok } from "@/lib/types/storyblok-blok-types";

import Skeleton from "@/components/ui/Skeleton";
import { getStreamVideoUrl } from "@/lib/utils/video";
import ReactPlayer from "react-player";
import styles from "./media.module.css";

const checkIsFull = (blok: VideoSectionStoryblok) => {
  return blok.alignment === "full";
};

const VideoElement = ({ blok }: { blok: VideoSectionStoryblok }) => {
  return (
    <div className={styles.videoWrap}>
      <ReactPlayer
        url={getStreamVideoUrl(blok.url)}
        controls={true}
        muted={false}
        width={"100%"}
        height={"100%"}
        className="react-player"
        fallback={<Skeleton height="100%" />}
      />
    </div>
  );
};

const VideoContent = ({
  blok,
  children,
}: {
  children: React.ReactElement;
  blok: VideoSectionStoryblok;
}) => {
  const full = checkIsFull(blok);

  return (
    <BlokWrap
      {...storyblokEditable(blok as unknown as SbBlokData)}
      className={clsx(
        styles.base,
        full && styles.full,
        blok.alignment === "left" && styles.left,
        blok.alignment === "right" && styles.right,
        "media-blok",
      )}
      data-blok
      data-blok-background
      data-full={full}
      data-alignment={blok.alignment}
      data-layout={"page"}
      animated={full ? "custom" : "fadeY"}
    >
      {children}
    </BlokWrap>
  );
};

const Video = ({ blok }: { blok: VideoSectionStoryblok }) => {
  return (
    <VideoContent blok={blok}>
      <VideoElement blok={blok} />
    </VideoContent>
  );
};

export default Video;
